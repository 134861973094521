import React from "react";
import { forwardRef } from "react";
import { InputField as LMInputField } from "@loblaw-media/components";
import { Controller } from "react-hook-form";
import LabelGroup from "modules/auth/components/LabelGroup";

const InputField = (props, ref) => {
  const { name, control, errors = {}, validation = {}, ...rest } = props;

  const inputClassName =
    props.type === "checkbox" || props.type === "switch"
      ? ""
      : "form-input login-username p-3 w-100";

  return (
    <LabelGroup field={name} errors={errors}>
      <Controller
        name={name}
        control={control}
        rules={validation}
        render={({ field }) => (
          <LMInputField
            {...field}
            {...rest}
            ref={ref}
            inputStyles={{
              className: inputClassName
            }}
            labelStyles={{
              className: "fw-normal"
            }}
          />
        )}
      />
    </LabelGroup>
  );
};

export default forwardRef(InputField);
