import styled from "styled-components";

const StyledFormButton = styled.div`
  padding: 8px 20px;
  color: #fff;
  background-color: ${props => props.theme.blue_jay};
  border: 1px solid ${props => props.theme.blue_jay};
  border-radius: 3px;
  display: inline-block;

  &:hover {
    background-color: transparent;
    color: ${props => props.theme.blue_jay};
    cursor: pointer;
  }
`;
export default StyledFormButton;
