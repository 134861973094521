import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useHistory } from "react-router-dom";

import Loading from "modules/auth/components/Loading";

/**
 * LOGOUT FLOW
 *
 * 1. Hit /session/logout, where you receive:
 * {
 *  logouts: [StringUrlToLogout],
 *  csrfToken: token
 * }
 * Where StringUrlToLogout will be one per platform, to log the user out of all our apps
 *
 * 2. Loop through logouts [] and make post to the StringUrlToLogout;
 *
 * 3. When all of those posts are resolved, there is a hidden form on the page that posts form data to /logout { _csrf: csrfToken }
 *
 * 4. Nav to /login?logout
 */

const POST_CONFIG = {
  timeout: 5000
};

function LogoutView() {
  const [logoutsComplete, setLogoutsComplete] = useState(false);

  // ref hook for programmatic form submit
  const formRef = useRef(null);

  // history hooks
  const history = useHistory();

  // form hooks
  const { handleSubmit, register, setValue } = useForm();

  //
  // useEffect hooks
  //
  useEffect(() => {
    async function logout() {
      try {
        await axios.post("/logout", {});
        setLogoutsComplete(true);
      } catch (e) {
        history.push("/login?unknown");
      }
    }

    logout();
  }, [setValue, history]);

  useEffect(() => {
    if (logoutsComplete) {
      formRef.current.click();
    }
  }, [logoutsComplete]);

  //
  // functions
  //
  const handleFormSubmit = async data => {
    await axios.post("/logout", data, POST_CONFIG);

    history.push("/login?logout");
  };

  return (
    <>
      <Loading message="Logging out..." />
      <form className="hidden" onSubmit={handleSubmit(handleFormSubmit)}>
        <input type="hidden" name="_csrf" {...register("_csrf")} />
        <input ref={formRef} type="submit" />
      </form>
    </>
  );
}

export default LogoutView;
