import React from "react";
import classnames from "classnames";
import styled from "styled-components";

function LabelGroup({ field, children, errors }) {
  return (
    <>
      <FieldContainer
        className={classnames(`input-container mb-3`, {
          "field-error": errors[field]
        })}
      >
        {children}
        {errors[field] && <ErrorMessage>{errors[field].message}</ErrorMessage>}
      </FieldContainer>
    </>
  );
}

const FieldContainer = styled.div`
  input {
    height: 50px;
    border-radius: 10px;
    border-width: thin;
  }
`;

const ErrorMessage = styled.div`
  font-size: 1.4rem;
  color: ${props => props.theme.lm_red};
  font-style: italic;
  position: absolute;
  top: 40px;
`;

export default LabelGroup;
