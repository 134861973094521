import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

function TileLink({ app, width, children }) {
  if (app.external) {
    return (
      <ExternalLink
        href={app.url}
        width={width}
        target="blank"
        rel="noopener noreferrer"
      >
        {children}
      </ExternalLink>
    );
  }

  return (
    <Link style={{ textDecoration: "none", width: width }} to={`/${app.url}`}>
      {children}
    </Link>
  );
}
export default TileLink;

const ExternalLink = styled.a`
  width: ${props => props.width} !important;
  text-decoration: none;
`;
