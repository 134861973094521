import createDataContext from "./createDataContext";

const INITIAL_STATE = {
  edit: false
};

const formReducer = (state, action) => {
  switch (action.type) {
    case "set_edit":
      return { ...state, edit: action.payload };
    default:
      return state;
  }
};

const actions = {
  setEdit: dispatch => {
    return isEditing => {
      dispatch({ type: "set_edit", payload: isEditing });
    };
  }
};

export const { Context, Provider } = createDataContext(
  formReducer,
  { ...actions },
  INITIAL_STATE
);
