import caLogo from "modules/auth/assets/icon-standaloneca.svg";
import accountsLogo from "modules/auth/assets/icon-user-accounts.png";
import profileLogo from "modules/auth/assets/icon-my-account.png";
import dspLogo from "modules/auth/assets/icon-dsp.png";
import cmLogo from "modules/auth/assets/icon-dsp.png";
import utmLogo from "modules/auth/assets/icon-utm.png";
import rmpLogo from "modules/auth/assets/icon-rmp.png";
import audienceLogo from "modules/auth/assets/icon-audience-planning.png";
import adbuilderLogo from "modules/auth/assets/icon-adbuilder.png";
import hmpbannerLogo from "modules/auth/assets/hmpbanner.svg";
import rmipLogo from "modules/auth/assets/shopper_insights.svg";

const EMAIL_REGEX = /\S+@\S+\.\S+/;

export const TILE_INFO_MAP = {
  analytics: {
    logo: caLogo,
    name: "Campaign Analytics",
    description:
      "Access your unique campaign performance data including sales attribution and omnichannel ROAS measurement."
  },
  eyecontrol: {
    logo: dspLogo
  },
  cm: {
    logo: cmLogo,
    name: "Demand Side Platform",
    description:
      "Your solution for buying programmatic display and online video ads linked to closed-loop purchase attribution."
  },
  profile: {
    logo: profileLogo,
    name: "My Account",
    description: "Access your user profile."
  },
  accounts: {
    logo: accountsLogo,
    name: "User Accounts",
    description: "Configure self-serve accounts."
  },
  utm: {
    logo: utmLogo,
    name: "Marketing Taxonomy Tool",
    description:
      "Create consistent data across systems, teams, and marketing activities."
  },
  rmp: {
    logo: rmpLogo,
    name: "Sponsored Products",
    description:
      "Drive conversion of your brands by promoting sponsored products in our e-commerce websites and apps."
  },
  hmp: {
    logo: hmpbannerLogo,
    name: "Omni-Channel Platform",
    description: `Access a comprehensive range of channels, including Loblaw owned and operated, third-party media, and in-store advertising with unified measurement.`,
    description2:
      "With intelligent platform recommendations using Advance™ Powered by Loblaw."
  },

  galapagos: {
    logo: audienceLogo,
    name: "Audience Hub",
    description:
      "Access our audience catalogue and build custom audiences using detailed product purchase criteria."
  },
  "ad-builder": {
    logo: adbuilderLogo,
    name: "Ad Builder",
    description:
      "Use Ad Builder to create compelling ad creatives featuring retail prices! Ideal for promoting different prices in creatives, flyer products, and in-store campaigns."
  },
  rmip: {
    logo: rmipLogo,
    name: "Shopper Insights",
    description:
      "Gain a competitive edge in retail media with actionable insights tailored to your product category and marketing goals. Discover how to optimize your campaigns and drive impactful results."
  }
};

export const FORM_VALIDATION = {
  username: {
    required: {
      value: true,
      message: "Required."
    },
    pattern: {
      value: EMAIL_REGEX,
      message: "Please provide a valid email address."
    },
    minLength: {
      value: 3,
      message: "The minimum number of characters is 3."
    }
  },
  password: {
    required: {
      value: true,
      message: "Required."
    },
    minLength: {
      value: 3,
      message: "The minimum number of characters is 3."
    }
  },
  code: {
    required: {
      value: true,
      message: "Required"
    },
    minLength: {
      value: 6,
      message: "Code must be six digits in length."
    },
    maxLength: {
      value: 6,
      message: "Code must be six digits in length."
    }
  }
};

export const LOGIN_VIEW_MESSAGES = {
  blocked:
    "Your account is locked due to too many failed login attempts. Please contact your account administrator.",
  logout: "You successfully logged out.",
  disabled: "Your account is disabled.",
  unavailable: "Authentication service is temporarily unavailable.",
  error: "Invalid username or password.",
  unknown: "Something has gone wrong, please try again.",
  pending:
    "Please contact your account administrator to activate Loblaw Platform access."
};

export const MFA_VIEW_MESSAGES = {
  error: "Invalid code.",
  blocked: "Too many unsuccessful attempts."
};

export const NEW_PASSWORD_COPY = {
  register: {
    header: "Set New Account Password",
    successHeader: "Successfully registered new account!",
    success: "Please visit the Login Page to sign in to your new account."
  },
  reset: {
    header: "Reset Your Password",
    successHeader: "Successfully reset password!",
    success: "Please visit the Login Page to sign in to your account."
  },
  noKey:
    "Key is invalid or expired. Please contact your administrator for a new link."
};
