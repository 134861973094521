import axios from "axios";

const authApi = axios.create({
  baseURL: "/web/auth",
  timeout: 20000,
  withCredentials: true
});

const sessionApi = axios.create({
  baseURL: "/session",
  timeout: 20000
});

const appApi = axios.create({
  baseURL: "/",
  timeout: 20000
});

/**
 * FUNTIMES AXIOS ISSUE
 *
 * Axios (and many other javascript fetch libraries, including native fetch),
 * only follow 302 redirects under the hood, and do not surface actual 302
 * redirect and location header response to axios to be returned to the client.
 *
 * This means that for auth, when the BE evaluates the form data POST reqs and
 * returns a 302 redirect to /login?error, /login?disabled etc, the browser
 * DOES NOT automatically follow this redirect (via url).
 *
 * Luckily, we only receive redirects like this this for the login form. I think.
 *
 * As a result, see this mostly hacky interceptor below that evaluates "success"
 * responses for /check_login, looks at the responseURL to see if there is a param,
 * and "follows" the redirect if a param exists.
 *
 * UGH.
 */

const POST_URLS_THAT_REDIRECT = ["/check_login", "/web/mfa/submit"];

function successResponseInterceptor(response) {
  if (
    POST_URLS_THAT_REDIRECT.includes(response.config.url) &&
    response.request.responseURL
  ) {
    window.location.href = response.request.responseURL;
    return;
  }

  return response;
}

appApi.interceptors.response.use(successResponseInterceptor);

export { sessionApi, appApi };
export default authApi;
