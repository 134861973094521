import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@loblaw-media/icons/fontawesome/pro-solid-svg-icons/faSpinner";

import { StyledPageOverlay } from "modules/auth/components-styled";

function RedirectToLogout() {
  //
  // useEffect hooks
  //
  useEffect(() => {
    setTimeout(() => (window.location.href = "/logout"), 3000);
  }, []);

  return (
    <StyledPageOverlay pmb={50}>
      <h3>Authenticator Successfully Enabled!</h3>
      <p>
        You will now be logged out to complete the Authenticator activation
        process.
      </p>
      <FontAwesomeIcon icon={faSpinner} spin size="3x" />
    </StyledPageOverlay>
  );
}

export default RedirectToLogout;
