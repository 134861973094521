import React from "react";

function BaseLayout({ children, header, footer }) {
  return (
    <div className="d-flex flex-column" style={{ height: "100vh" }}>
      <div style={{ zIndex: 2 }}>{header}</div>
      <div>{children}</div>
      <div className="mt-auto">{footer}</div>
    </div>
  );
}

export default BaseLayout;
