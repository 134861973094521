import styled from "styled-components";

const StyledButton = styled.button`
  padding: 8px 20px;
  color: ${props => props.theme.font_light};
  background-color: ${props =>
    props.disabled ? props.theme.disabled_dark : props.theme.blue_jay};
  margin-left: ${props => (props.ml ? `${props.ml}px` : 0)};
  border: ${props =>
    `1px solid ${
      props.disabled ? props.theme.disabled_dark : props.theme.blue_jay
    }`};
  border-radius: 3px;
  display: inline-block;

  ${props =>
    !props.disabled &&
    `&:hover {
    background-color: transparent;
    color: ${props.theme.blue_jay};
    cursor: pointer;
  }`}
`;

export default StyledButton;
