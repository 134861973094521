import React, { useRef } from "react";
import { useForm } from "react-hook-form";
import objectPath from "object-path";

import InputField from "../InputField";
import { appApi } from "modules/auth/api";

function NewPasswordForm({
  submitRef,
  setSuccess,
  defaultValues,
  setPageError
}) {
  //
  // form hooks
  //
  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
    clearErrors,
    watch,
    control
  } = useForm({
    defaultValues
  });

  //
  // useRef hooks
  //
  const password = useRef({});
  password.current = watch("new_password", "");

  //
  // functions
  //
  const handleFormSubmit = async ({ confirm_password, ...data }) => {
    clearErrors();

    try {
      await appApi({
        url: "/web/password/insecure/reset",
        method: "POST",
        data
      });

      setSuccess(true);
    } catch (err) {
      const validationErrors = objectPath.get(
        err,
        "response.data.validation_errors"
      );

      if (validationErrors) {
        validationErrors.map(e => {
          if (e.property === "key") {
            // case: show page overlay for hidden key field
            return setPageError({ message: e.message });
          }

          return setError(e.property, {
            message: e.message
          });
        });
      } else {
        throw err;
      }
    }
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit(handleFormSubmit)}>
      <InputField
        name="new_password"
        label="New Password"
        type="password"
        control={control}
        size="lg"
        isVertical={true}
        errors={errors}
      />
      <InputField
        name="confirm_password"
        label="Confirm Password"
        type="password"
        control={control}
        size="lg"
        validation={{
          validate: value =>
            value === password.current || "The passwords do not match"
        }}
        isVertical={true}
        errors={errors}
      />
      <input type="hidden" name="key" {...register("key")} />

      <input ref={submitRef} type="submit" className="hidden" />
    </form>
  );
}

export default NewPasswordForm;
