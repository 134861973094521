import React from "react";
import styled from "styled-components";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Button, Card } from "@mui/material";

import { TILE_INFO_MAP } from "modules/auth/constants";
import { ReactComponent as LoopPlatformIcon } from "modules/auth/assets/LoopPlatformIcon.svg";
import { ReactComponent as InsightsPlatformIcon } from "modules/auth/assets/shopper_insights.svg";
import TileLink from "./TileLink";

const AppTile = ({ data, isHmp = false, isRmip = false }) => {
  const { name, identifier, url, external, description } = data;

  const altText =
    "Isometric illustration of a computer screen with a shopping cart icon and a large cursor. Behind the screen are two additional charts: a line graph with purple and green lines, and a pie chart with purple and green sections.";

  const rmipAltText =
    "Illustration of a laptop displaying analytics. The screen shows a line graph with a yellow curve and purple background elements, along with other charts and data visuals.";

  if (isHmp) {
    return (
      <StyledCard isHmp={isHmp}>
        <div className="d-flex flex-row" style={{ height: "100%" }}>
          <div className="card-content pe-4">
            <div className="content">
              <TileLink app={{ url, external }}>
                <h2 className="fw-bold">
                  {name || TILE_INFO_MAP[identifier].name}
                </h2>
              </TileLink>
              <p className="pt-2">
                {description || TILE_INFO_MAP[identifier].description}
              </p>
            </div>
            <TileLink app={{ url, external }}>
              <Button variant="contained" color="primary" size="large">
                Go to Platform <ArrowForwardIcon sx={{ fontSize: 26 }} />
              </Button>
            </TileLink>
          </div>
          <div className="ps-4">
            <LoopPlatformIcon aria-label={altText} />
          </div>
        </div>
      </StyledCard>
    );
  }

  if (isRmip) {
    return (
      <StyledCard isRmip={isRmip}>
        <div className="d-flex flex-row" style={{ height: "100%" }}>
          <div className="card-content pe-4">
            <div className="content">
              <TileLink app={{ url, external }}>
                <h2 className="fw-bold">
                  {name || TILE_INFO_MAP[identifier].name}
                </h2>
              </TileLink>
              <p className="pt-2">
                {description || TILE_INFO_MAP[identifier].description}
              </p>
            </div>
            <TileLink app={{ url, external }}>
              <Button variant="contained" color="primary" size="medium">
                Get Started <ArrowForwardIcon sx={{ fontSize: 26 }} />
              </Button>
            </TileLink>
          </div>
          <div className="ps-4">
            <InsightsPlatformIcon aria-label={rmipAltText} />
          </div>
        </div>
      </StyledCard>
    );
  }

  return (
    <StyledCard isHmp={isHmp}>
      <div className="card-content">
        <div className="content">
          <TileLink app={{ url, external }}>
            <h2 className="fw-bold">
              {name || TILE_INFO_MAP[identifier].name}
            </h2>
          </TileLink>
          <p className="pt-2">
            {description || TILE_INFO_MAP[identifier].description}
          </p>
        </div>
        <TileLink app={{ url, external }}>
          <Button variant="outlined" color="primary" size="small">
            <ArrowForwardIcon sx={{ fontSize: 26 }} />
          </Button>
        </TileLink>
      </div>
    </StyledCard>
  );
};

const StyledCard = styled(Card).withConfig({
  shouldForwardProp: prop => prop !== "isHmp" && prop !== "isRmip"
})`
  height: 328px;
  width: ${props =>
    props.isHmp || props.isRmip ? "calc(2 * 330px + 16px)" : "330px"};
  padding: 36px;
  p {
    color: ${props => props.theme.charcoal};
  }

  h2 {
    color: ${props => props.theme.charcoal};

    :hover {
      text-decoration: underline;
    }
  }

  ${props =>
    (props.isHmp || props.isRmip) &&
    `
    grid-column: span 2;
  `}

  .card-content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .content {
    flex: 1;
  }
`;

export default AppTile;
