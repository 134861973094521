import React, { useContext, useEffect } from "react";
import { Switch, Route } from "react-router-dom";

import { Context as UserContext } from "modules/auth/context/UserContext";
import {
  DashboardView,
  AuthenticatorView,
  ProfileView,
  UpdatePasswordView
} from "modules/auth/views";

import authApi from "modules/auth/api";
import PageOverlay from "../components/PageOverlay";
import UpdateEmailView from "../views/UpdateEmailView";
function AuthenticatedRoutes() {
  //
  // context hooks
  //
  const {
    setUser,
    setError,
    destroyUser,
    state: { error }
  } = useContext(UserContext);

  useEffect(() => {
    async function fetchUser() {
      try {
        const response = await authApi.get("/user");

        setUser(response.data.content);
      } catch (e) {
        setError(true);
      }
    }

    fetchUser();

    return destroyUser;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) {
    return <PageOverlay />;
  }

  return (
    <Switch>
      <Route exact path="/profile" component={ProfileView} />

      <Route exact path="/profile/password" component={UpdatePasswordView} />

      <Route exact path="/profile/email" component={UpdateEmailView} />

      <Route exact path="/profile/totp" component={AuthenticatorView} />

      <Route exact path="/" component={DashboardView} />
    </Switch>
  );
}

export default AuthenticatedRoutes;
