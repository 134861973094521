import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import objectPath from "object-path";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { Context as UserContext } from "modules/auth/context/UserContext";
import InputField from "../InputField";
import { appApi } from "modules/auth/api";

function UpdateEmailForm({ defaultValues, submitRef }) {
  const {
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    control
  } = useForm({
    defaultValues
  });
  const { setUser } = useContext(UserContext);
  const history = useHistory();

  const handleFormSubmit = async data => {
    const { email, password } = data;
    clearErrors();

    try {
      const response = await appApi({
        url: "/web/profile/email",
        method: "POST",
        data: {
          email,
          password
        }
      });

      setUser(response.data.content);
      history.push("/profile");
      toast.success("Successfully updated email.");
    } catch (err) {
      const validationErrors = objectPath.get(
        err,
        "response.data.validation_errors"
      );

      if (validationErrors) {
        validationErrors.map(e => {
          return setError(e.property, {
            message: e.message
          });
        });
      } else {
        throw err;
      }
    }
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit(handleFormSubmit)}>
      <InputField
        name="email"
        label="Email"
        type="email"
        control={control}
        size="lg"
        required
        isVertical={true}
        errors={errors}
      />
      <InputField
        name="password"
        label="Password"
        type="password"
        control={control}
        size="lg"
        required
        isVertical={true}
        errors={errors}
      />
      <input ref={submitRef} type="submit" className="hidden" />
    </form>
  );
}

export default UpdateEmailForm;
