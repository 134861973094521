import React, { useState, useEffect, useRef } from "react";
import qs from "qs";
import { Link } from "react-router-dom";

import FormViewWrapper from "modules/auth/components/FormViewWrapper";
import PageOverlay from "modules/auth/components/PageOverlay";
import ViewActions from "./ViewActions";
import NewPasswordForm from "modules/auth/forms/NewPasswordForm";
import { Context as FormContext } from "modules/auth/context/FormContext";
import { NEW_PASSWORD_COPY } from "modules/auth/constants";
import PasswordRules from "./UpdatePasswordView/PasswordRules";

function NewPasswordViewComponent({ view, location: { search } }) {
  //
  // state hooks
  //
  const [queryParams, setQueryParams] = useState({});
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState({});

  //
  // ref hooks for programmatic form submit
  //
  const submitRef = useRef(null);

  useEffect(() => {
    const params = qs.parse(search, {
      ignoreQueryPrefix: true
    });

    if (params.key) {
      setQueryParams(params);
    } else {
      setError({ message: NEW_PASSWORD_COPY.noKey });
    }
  }, [search]);

  const returnToLogin = <Link to="/login">Return to Login</Link>;

  // case: no key param (in case of manual navigation via url),
  // or error with hidden key field
  if (error.message || !queryParams.key) {
    const content = (
      <>
        <p>{error.message}</p>
        {returnToLogin}
      </>
    );

    return (
      <PageOverlay
        messageTitle="Something has gone wrong..."
        messageBody=""
        content={content}
        showActions={false}
      />
    );
  }

  // case: successful form submit
  if (success) {
    return (
      <PageOverlay
        messageTitle={NEW_PASSWORD_COPY[view].successHeader}
        messageBody={NEW_PASSWORD_COPY[view].success}
        content={returnToLogin}
        showActions={false}
      />
    );
  }

  const header = (
    <>
      <h2>{NEW_PASSWORD_COPY[view].header}</h2>
      <ViewActions submitRef={submitRef} submitOnly />
    </>
  );

  // case: there is a key param, show the password form
  return (
    <FormViewWrapper
      viewHeader={header}
      form={
        <NewPasswordForm
          submitRef={submitRef}
          defaultValues={{ key: queryParams.key }}
          view={view}
          setSuccess={setSuccess}
          setPageError={setError}
        />
      }
      viewAppend={<PasswordRules />}
    />
  );
}

const NewPasswordView = props => {
  return (
    <FormContext.Provider value={{ state: { edit: true } }}>
      <NewPasswordViewComponent {...props} />
    </FormContext.Provider>
  );
};

export default NewPasswordView;
