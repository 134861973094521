import createDataContext from "./createDataContext";

const INITIAL_STATE = {
  user: null,
  error: false
};

const userReducer = (state, action) => {
  switch (action.type) {
    case "set_user":
      return { ...state, user: action.payload };

    case "set_error":
      return { ...state, error: action.payload };

    default:
      return state;
  }
};

const actions = {
  setUser: dispatch => {
    return user => {
      dispatch({ type: "set_user", payload: user });
    };
  },
  setError: dispatch => {
    return error => {
      dispatch({ type: "set_error", payload: error });
    };
  },
  destroyUser: dispatch => {
    return () => {
      dispatch({ type: "set_user", payload: null });
    };
  }
};

export const { Context, Provider } = createDataContext(
  userReducer,
  { ...actions },
  INITIAL_STATE
);
